@import "../../constants/variables";

.Gallery {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__photo {
    cursor: pointer;
    flex: 0 0 33%;
    @media (max-width: $breakpoint-desktop) {
      flex: 0 0 50%;
    }
    @media (max-width: $breakpoint-mobile-big) {
      flex: 0 0 100%;
    }
    display: flex;
    flex-direction: column;
    align-items: center;

    figure {
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      margin: 0 1rem 2rem 1rem;
    }

    img {
      height: 20rem;
      width: 100%;
      object-fit: cover;
    }

    figcaption {
      margin-top: 0.5rem;
    }
  }
}