@import "../../constants/variables";

%link {
  text-transform: lowercase;
  color: $color-text-light;
  font-family: 'Open Sans';
  font-size: 1.4rem;
  background-color: $primary-orange;
  line-height: 3rem;
  height: 3rem;
  text-align: center;

  @media(min-width: $breakpoint-nav) {
    padding: 0 1rem;
  }

  &:hover {
    background-color: $color-nav-hover;
  }
}

.Header {
  //display: flex;
  //align-items: center;

  display: grid;
  grid-template-columns: 6rem auto;
  grid-template-rows: 3rem 3rem;
  grid-column-gap: 0.5rem;

  @media(max-width: $breakpoint-nav) {
    grid-template-columns: 3rem auto;
    grid-template-rows: 3rem 3rem auto;
  }

  @media(max-width: $breakpoint-mobile-small) {
    grid-template-columns: 6rem auto;
    grid-template-rows: 6rem 3rem auto;
  }

  @media(max-width: $breakpoint-mobile-extrasmall) {
    grid-template-rows: 9rem 3rem auto;
  }

  &__logo {
    display: flex;
    margin-right: 0.4rem;
    grid-area: 1 / 1 / 3 / 2;

    @media(max-width: $breakpoint-nav) {
      grid-area: 1 / 1 / 2 / 2;
    }

    img {
      width: 100%;
    }
  }

  &__text {
    font-family: 'Open Sans Condensed';
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 2.3rem;
    line-height: 2.3rem;
    font-weight: 400;
    color: $color-text-header;
    margin-bottom: 0.4rem;
    display: flex;

    @media(max-width: $breakpoint-mobile-small) {
      margin-top: 0.3rem;
      flex-direction: column;
    }

    &--strong {
      font-weight: 600;
      margin-left: 0.5rem;

      @media(max-width: $breakpoint-mobile-small) {
        margin-left: 0;
        margin-top: 0.5rem;
      }
    }
  }

  &__nav-switch {
    display: none;
    @extend %link;
    cursor: pointer;

    @media(max-width: $breakpoint-nav) {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-area: 2 / 1 / 3 / 3;
    }
  }

  &__nav-switch-icon {
    div {
      width: 35px;
      height: 5px;
      background-color: $color-text-light;
      margin: 5px auto;
      transition: 0.4s;
    }

    &--opened {
      div:nth-child(1) {
        transform: rotate(-45deg) translate(-7px, 6px) ;
      }

      div:nth-child(2) {
        opacity: 0;
      }

      div:nth-child(3) {
        transform: rotate(45deg) translate(-8px, -8px) ;
      }
    }
  }

  &__nav {
    display: flex;

    @media(max-width: $breakpoint-nav) {
      display: none;
      flex-direction: column;
      grid-area: 3 / 1 / 4 / 3;
    }

    &--show {
      display: flex;
    }
  }

  &__link {
    @extend %link;
  }
}