@import "../../constants/variables";
@import "../../constants/mixins";

.Edition {

  &__header {
    @extend %header-primary;
  }

  &__topbar {
    @extend %topbar;
  }

  &__read-more {
    color: $primary-blue;
    font-weight: bold;
    cursor: pointer;
  }

  &__photo {
    figure {
      margin-inline-start: 0;
      margin-inline-end: 0;
      margin-right: 1rem;
    }

    @media(max-width: $breakpoint-mobile-small) {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  &__qrcode {
    margin-bottom: 1rem;
  }
}

.link {
  margin-top: auto !important;
}
