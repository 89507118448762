@import "../../constants/variables";

.Footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: $color-footer;
  color: $color-text-light;
  font-family: $font-primary;
  margin-top: auto;

  @media(max-width: $breakpoint-mobile-small) {
    display: block;
    width: 100%;
  }
}

.Footer-nav {
  display: flex;
  align-items: center;
  margin: 0.6rem 0;
  text-transform: uppercase;
  font-size: 1.2rem;

  @media(max-width: $breakpoint-mobile-small) {
    justify-content: space-between;
  }

  &__item:hover {
    color: $color-text-light-hovered;
  }

  &__logo {
    display: flex;
    align-items: center;

    span {
      margin-left: 0.4rem;
    }
  }

  &__lang {
    margin-left: 1.4rem;

    button {
      cursor: pointer;
    }
  }
}

.Footer-icons {
  display: flex;
  align-items: center;
  margin: 0.6rem 0;
  text-transform: uppercase;
  font-size: 1.2rem;
}