@import "../../constants/variables";
@import "../../constants/mixins";

.Contact {

  &__header {
    @extend %header-primary;
    margin-bottom: 0.5rem;
  }

  &__social-media {

  }

  &__social-media-items {
    display: flex;
    margin-top: 0.5rem;
  }

  &__social-media-item {
    margin-right: 0.5rem;
  }
}