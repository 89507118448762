@import "../../constants/variables";
@import "../../constants/mixins";

.Authors {

  &__header {
    @extend %header-primary;
    margin-top: 0;
  }

  &__topbar {
    @extend %topbar;
  }

  &__filter {
    @extend %filter;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;

    @media(max-width: $breakpoint-mobile-small) {
      justify-content: center;
    }
  }
}

.Author-item {
  @extend %item;

  @media(max-width: $breakpoint-mobile-small) {
    flex-direction: column;
  }

  &__photo {
    @extend %item-photo;
    background-color: lighten($primary-gray, 30%);

    @media(max-width: $breakpoint-mobile-small) {
      width: 21rem;
      height: 21rem;
      margin-bottom: 1rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__wrapper {
    flex: 0 0 50%;

    @media(max-width: $breakpoint-authors) {
      flex: 0 0 100%;
    }

    @media(max-width: $breakpoint-mobile-small) {
      flex: initial;
    }
  }

  &__data {
    font-size: 1.1rem;
    color: $primary-black;
    height: 12rem;
    overflow: hidden;
    text-overflow: ellipsis;

    @media(max-width: $breakpoint-mobile-small) {
      width: 21rem;
      height: auto;
    }
  }

  &__name {
    font-weight: bold;
  }
}