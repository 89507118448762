@import "../../constants/variables";
@import "../../constants/mixins";

.Artwork {

  &__header {
    @extend %header-primary;
  }

  &__images {
    .Gallery {
      justify-content: flex-start;
    }
  }

  &__data {
    margin-top: 1rem;
    font-size: 1.2rem;
  }

  &__data-bullet {

  }

  &__data-label {
    width: 10rem;
    display: inline-block;
    font-weight: bold;
  }

  a {
    color: $primary-orange;
    text-decoration: underline;
  }
}