@import "../../constants/variables";
@import "../../constants/mixins";

.Artworks {

  &__topbar {
    @extend %topbar;
  }

  &__header {
    @extend %header-primary;
    margin-top: 0;
  }

  &__filter {
    @extend %filter;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
  }

  &__separator {
    font-size: 1.4rem;
    margin-top: 1rem;
    width: 50%;
    border-bottom: 1px solid rgba(0,0,0,0.5);
    padding-bottom: 0.5rem;
  }

  &__none-found {
    font-size: 1.3rem;
    margin: 1rem 0;
  }

  &__limit-button {
    cursor: pointer;
    font-size: 1.2rem;
    padding: 0.4rem 0.6rem;
    background: lighten($primary-gray, 50%);
    margin-left: 0.3rem;
    margin-bottom: 0.3rem;

    &--selected {
      background: transparent;
      cursor: initial;
    }
  }
}

.Artwork-item {
  @extend %item;

  &__photo {
    @extend %item-photo;
    background-color: lighten($primary-gray, 30%);
  }

  &__wrapper {
    flex: 0 0 50%;

    @media(max-width: $breakpoint-mobile-big) {
      flex: 0 0 100%;
    }
  }

  &__data {
    font-size: 1.3rem;
    color: $primary-black;
  }

  &__name {
    font-weight: bold;
  }
}