%header-primary {
  margin-top: 1rem;
  font-size: 2rem;
  font-family: $font-primary;
}

%item {
  display: flex;
  cursor: pointer;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 0.7rem;
  background: lighten($primary-gray, 50%);
}

%item-photo {
  width: 12rem;
  height: 12rem;
  margin-right: 0.5rem;
  flex-shrink: 0;

  img {
    width: 12rem;
    height: 12rem;
    object-fit: cover;
  }
}

%filter {
  font-size: 1.1rem;

  @media(max-width: $breakpoint-mobile-small) {
    form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  input, select {
    font: inherit;
    padding: 0.5em;
    margin-left: 0.5rem;

    @media(max-width: $breakpoint-mobile-small) {
      margin-left: 0;
    }
  }

  label {
    margin-left: 1rem;

    @media(max-width: $breakpoint-mobile-big) {
      margin-left: 0;
      margin-right: 1rem;
    }

    @media(max-width: $breakpoint-mobile-small) {
      margin-right: 0;
      margin-bottom: 0.5rem;

      & > span {
        display: inline-block;
        width: 4rem;
      }
    }
  }
}

%topbar {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media(max-width: $breakpoint-mobile-big) {
    flex-direction: column;
    align-items: flex-start;
  }
}