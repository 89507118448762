@import "../../constants/variables";
@import "../../constants/mixins";

.Editions {

  &__header {
    @extend %header-primary;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;

    @media(max-width: $breakpoint-mobile-small) {
      align-items: center;
      flex-direction: column;
    }
  }
}

.Edition-item {
  cursor: pointer;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 0.5em;
  background: lighten($primary-gray, 50%);

  @media(max-width: $breakpoint-mobile-small) {
    padding: 1rem;
    margin-right: 0;
  }

  &__year {
    font-size: 1.3rem;
    padding-top: 0.5rem;
    text-align: center;
    color: $primary-black;
  }

  &__photo {
    width: 12rem;
    height: 12rem;
    background-color: lighten($primary-gray, 30%);

    @media(max-width: $breakpoint-mobile-small) {
      width: 21rem;
      height: 21rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}