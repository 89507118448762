@import "../../constants/variables";
@import "../../constants/mixins";

.Files {
  &__header {
    @extend %header-primary;
    margin-top: 0;
  }

  &__topbar {
    @extend %topbar;
  }

  &__filter {
    @extend %filter;
  }

  &__items {
    width: 100%;
    margin: 1rem 0rem 10rem;
    text-align: center;

    div.files {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      img,
      video,
      iframe {
        max-height: 15rem;
      }
    }

    @media (max-width: $breakpoint-mobile-small) {
      justify-content: center;
    }
  }

  &__folders {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    figure {
      text-align: center;
    }
  }
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.clickable {
  cursor: pointer;
}
