@import "constants/variables";

:root {
  font-size: 16px;

  @media(max-width: $breakpoint-desktop) {
    font-size: 15px;
  }
  @media(max-width: $breakpoint-mobile-big) {
    font-size: 14px;
  }
  @media(max-width: $breakpoint-mobile-small) {
    font-size: 13px;
  }
}

body {
  margin: 0;
  //min-height: 100vh;
}

.App-container {
  padding-top: 1rem;
  margin: 0 auto;
  //min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: $font-secondary;
  width: $content-width-desktop;
  @media(max-width: $breakpoint-desktop) {
    width: 80%;
  }
  @media(max-width: $breakpoint-mobile-big) {
    width: 90%;
  }
  @media(max-width: $breakpoint-mobile-small) {
    width: 95%;
  }
}

.Main {

}

a {
  text-decoration: none;
  color: inherit;
}

button {
  text-transform: inherit;
  font: inherit;
  color: inherit;
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 1rem;
  margin: 0;
  font-weight: normal;
}

* {
  box-sizing: border-box;
}

p {
  margin: 0;
}

img[src=''], img[src='0'] {
  width: 100%;
  height: 100%;
  background-image: url("images/no-photo.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}