@import "../../constants/variables";

.Pagination {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  overflow-x: auto;
  display: flex;

  &__item {
    cursor: pointer;
    font-size: 1.2rem;
    padding: 0.8rem;
    background: lighten($primary-gray, 50%);
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
    //width: 2.4rem;

    &--selected {
      background: transparent;
      cursor: initial;
    }
  }
}