// colors
$primary-gray: #646567;
$primary-orange: #F7941D;
$primary-black: #000000;
$primary-blue: #4D7EA8;

$color-text-light: #FFFFFF;
$color-text-light-hovered: #CCCCCC;
$color-text-header: $primary-gray;
$color-nav: $primary-orange;
$color-nav-hover: #FBAF53;
$color-footer: $primary-gray;

// container
$content-width-desktop: 1200px;

// fonts
$font-primary: 'Open Sans Condensed';
$font-secondary: 'Roboto', sans-serif;

// breakpoints
$breakpoint-desktop: 1300px;
$breakpoint-authors: 1200px;
$breakpoint-nav: 1100px;
$breakpoint-mobile-big: 900px;
$breakpoint-mobile-small: 600px;
$breakpoint-mobile-extrasmall: 356px;