@import "../../constants/variables";
@import "../../constants/mixins";

.Home {
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;

  &__photo {
    position: relative;

    img {
      width: 100%;
    }
  }

  &__description {
    background-color: rgba(42, 60, 83, 0.9);
    color: $color-text-light;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    padding: 0.75rem;
    border-radius: 0.5rem;

    @media(max-width: $breakpoint-mobile-small) {
      width: 90%;
      bottom: 1rem;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__description-header {
    font-size: 1.5rem;
    margin-bottom: 0.3rem;
  }

}