@import "../../constants/variables";
@import "../../constants/mixins";

.Author {
  &__header {
    @extend %header-primary;
  }

  &__topbar {
    @extend %topbar;
  }

  &__photo {
    margin: 0.5rem 0;

    figure {
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
}

.link {
  margin-top: auto !important;
}
